// in src/Menu.js
import * as React from 'react';
import {Menu, useTranslate, usePermissions} from 'react-admin';
import {useEffect, useState} from "react";
import {MenuItem} from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {getValidPrivilegesIds} from "./authentication/PermissionHandler";
import dataProvider from "./dataProvider";

function GetTranslate(translateKey){
    const translate = useTranslate()
    return translate(translateKey)
}

function GetPermission(resource, method, corpAdmin){
    const permissions = usePermissions().permissions
    return getValidPrivilegesIds(resource, method,corpAdmin || false).some(r => permissions.includes(r))
}

const AdministrationRender = () => {
    const [openAdministration, setOpenAdministration] = useState(false)
    return <div key={"administrationMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        <MenuItem onClick={() => setOpenAdministration(!openAdministration)} >
            {openAdministration ? <KeyboardDoubleArrowDownIcon />  : <KeyboardDoubleArrowRightIcon />}
            {GetTranslate('menu.administration')}
        </MenuItem>
        {openAdministration &&
            <div key={"administrationMenuItems"} style={{paddingLeft: '10px'}}>
                <Menu.ResourceItem name="company" />
                <Menu.ResourceItem name="businessUnit"/>
            </div>
        }
    </div>
}

const PriceListMenuItems = () => {
    return(
    <div key={"priceListMenuItems"} style={{paddingLeft: '10px'}}>
        { GetPermission("PRICELIST", "LIST") && <Menu.ResourceItem name="priceList"/> }
        { GetPermission("PRICELIST", "CREATE") && <Menu.ResourceItem name="quickMenu"/> }
        { GetPermission("PRICELIST_TO_POSSET", "LIST")  && <Menu.ResourceItem name="priceListToPosSet"/> }
        { GetPermission("POS_WITH_PRICELIST", "LIST") && <Menu.ResourceItem name="pos/pricelist"/> }
    </div>
    )
}

const WarehouseMenuItems = () => {
    return <div key={"warehouseMenuItems"} style={{paddingLeft: '10px'}}>
       <Menu.ResourceItem name="warehouse-product"/>
       <Menu.ResourceItem name="warehouse"/>
       <Menu.ResourceItem name="supplier"/>
       <Menu.ResourceItem name="warehouse-operation"/>
       <Menu.ResourceItem name="inventory"/>
       <Menu.ResourceItem name="stock-change-report/report" />
       <Menu.ResourceItem name="stockpile"/>
    </div>
}

const PriceListRender = () => {
    const [openPriceList, setOpenPriceList] = useState(false)
    const notEmpty = GetPermission("PRICELIST", "LIST") || GetPermission("PRICELIST", "CREATE") || GetPermission("PRICELIST_TO_POSSET", "LIST") || GetPermission("POS_WITH_PRICELIST", "LIST")
    const allSimplified = localStorage.getItem('allSimplified')
return(
allSimplified === 'true' ?
        <Menu.ResourceItem name="quickMenu"/>
    : (
    <div key={"priceListMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        {notEmpty && <MenuItem onClick={() => setOpenPriceList(!openPriceList)}>
            {openPriceList ? <KeyboardDoubleArrowDownIcon />  : <KeyboardDoubleArrowRightIcon />}
            {GetTranslate('menu.priceList')}
        </MenuItem>}
        { openPriceList && <PriceListMenuItems /> }
    </div>
    )
)}

const WarehouseRender = () => {
    const [openStorage, setOpenStorage] = useState(false)
    const notEmpty = GetPermission("WAREHOUSE", "LIST") || GetPermission("WAREHOUSE", "CREATE") || GetPermission("WAREHOUSE", "UPDATE") || GetPermission("WAREHOUSE", "DELETE")
    return <div key={"warehouseMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        {notEmpty && <MenuItem onClick={() => setOpenStorage(!openStorage)}>
            {openStorage ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
            {GetTranslate('menu.storage')}
        </MenuItem>}
        {openStorage && <WarehouseMenuItems/>}
    </div>
}

const CorporateAdminRender = () => {
    const [openCorporateAdmin, setOpenCorporateAdmin] = useState(false)
    const visible = GetPermission("PURCHASE", "LIST", true) ||
        GetPermission("PURCHASE", "STORNO", true) ||
        GetPermission("SHIFT", "CLOSE", true) ||
        GetPermission("SHIFT", "LIST", true) ||
        GetPermission("LICENSE", "GET", true) ||
        GetPermission("LICENSE", "UPDATE", true)

    return <div key={"corporateAdminMenu"} style={{backgroundColor: '#efefef', color: "#666666"}}>
        {visible && <MenuItem onClick={() => setOpenCorporateAdmin(!openCorporateAdmin)}>
            {openCorporateAdmin ? <KeyboardDoubleArrowDownIcon/> : <KeyboardDoubleArrowRightIcon/>}
            {GetTranslate('menu.corporateAdmin')}
        </MenuItem>}
        {openCorporateAdmin && <div key={"corporateAdminMenuItems"} style={{paddingLeft: '10px'}}>
            <Menu.ResourceItem name={"manageLicense"}/>
            <Menu.ResourceItem name={"purchase"}/>
            <Menu.ResourceItem name={"shift"}/>
            <Menu.ResourceItem name={"finance"}/>
            <Menu.ResourceItem name={"voucherGenerator"}/>
        </div>}
    </div>
}

export const PinkMenu = (props) => {
    const [isShowNTAK, setIsShowNTAK] = useState(true)
    useEffect(() => {
        dataProvider.get('ntak/pos-sets')
            .then((value) => {
                value.data && value.data.list.length > 0 ? setIsShowNTAK(true) : setIsShowNTAK(false)
            })
    },[])
    return(
        <Menu {...props}>

            <AdministrationRender />

            { GetPermission("PRODUCT", "LIST") && <Menu.ResourceItem name="product"/> }

            <PriceListRender/>

            { GetPermission("USER", "LIST") && <Menu.ResourceItem name="user"/> }

            { GetPermission("GROUP", "LIST") && <Menu.ResourceItem name="group"/> }

            { GetPermission("CONFIG", "LIST") && <Menu.ResourceItem name="configToPosSet"/> }

            { GetPermission("DEVICE", "LIST") && <Menu.ResourceItem name="device"/> }

            { GetPermission("RMS_ERROR_MESSAGES", "LIST") && isShowNTAK && <Menu.ResourceItem name="rMSErrorMessages"/> }

            { GetPermission("REPORT", "CREATE") && <Menu.ResourceItem name="report"/> }

            { GetPermission("DASHBOARD", "LIST") && <Menu.ResourceItem name="dashboard"/> }

            <WarehouseRender/>

            <Menu.ResourceItem name="license"/>

            <CorporateAdminRender />
        </Menu>
)};